<template>
  <div class="background">
    <Documents />
  </div>
</template>

<script>
import Documents from "@/components/elements/dashboard/documents/Documents";

export default {
  name: "DocumentManagementDocuments",

  components: {
    Documents,
  },

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped></style>
