<template>
  <div>
    <v-card class="pa-4 mt-5 pa-lg-7" elevation="1" rounded="lg">
      <v-card flat>
        <v-data-table
          :headers="headers"
          :items="documents"
          sort-by="id"
          :sort-desc="true"
          class="elevation-1"
          :page.sync="page"
          :items-per-page="documentsPerPage"
          hide-default-footer
        >
          >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Documents</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" persistent max-width="500px">
                <template
                  v-if="$can('document_create')"
                  v-slot:activator="{ on, attrs }"
                >
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    New Document
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-form ref="form" lazy-validation>
                        <v-row>
                          <!-- Vendor Name -->
                          <!-- <v-col cols="12">
                            <v-text-field
                              v-model="editedDocument.vendor_name"
                              :rules="vendorNameRules"
                              label="Vendor Name"
                              required
                            >
                              <template v-slot:label>
                                <p class="body-1">
                                  Vendor Name <span class="red--text">*</span>
                                </p>
                              </template>
                            </v-text-field>
                          </v-col> -->

                          <!-- Date -->
                          <!-- <v-col class="mt-n5" cols="12">
                            <v-menu
                              v-model="dateMenu"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="date"
                                  label="Date"
                                  prepend-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <template v-slot:label>
                                    <p class="body-1">
                                      Date <span class="red--text">*</span>
                                    </p>
                                  </template>
                                </v-text-field>
                              </template>
                              <v-date-picker
                                v-model="date"
                                @input="dateMenu = false"
                              ></v-date-picker>
                            </v-menu>
                          </v-col> -->

                          <!-- Tax -->
                          <!-- <v-col class="mt-n5" cols="12">
                            <v-text-field
                              v-model="editedDocument.tax"
                              :rules="taxRules"
                              label="Tax"
                              type="number"
                              required
                            >
                              <template v-slot:label>
                                <p class="body-1">
                                  Tax
                                  <span class="red--text">*</span>
                                </p>
                              </template>
                            </v-text-field>
                          </v-col> -->

                          <!-- Total Before Tax -->
                          <!-- <v-col class="mt-n5" cols="12">
                            <v-text-field
                              v-model="editedDocument.total_before_tax"
                              :rules="totalBeforeTaxRules"
                              label="Total Before Tax"
                              type="number"
                              required
                            >
                              <template v-slot:label>
                                <p class="body-1">
                                  Total Before Tax
                                  <span class="red--text">*</span>
                                </p>
                              </template>
                            </v-text-field>
                          </v-col> -->

                          <!-- Total -->
                          <!-- <v-col class="mt-n5" cols="12">
                            <v-text-field
                              v-model="editedDocument.total"
                              :rules="totalRules"
                              label="Total"
                              type="number"
                              required
                            >
                              <template v-slot:label>
                                <p class="body-1">
                                  Total
                                  <span class="red--text">*</span>
                                </p>
                              </template>
                            </v-text-field>
                          </v-col> -->

                          <!-- Total Currency -->
                          <!-- <v-col class="mt-n5" cols="12">
                            <v-text-field
                              v-model="editedDocument.total_currency"
                              :rules="totalCurrencyRules"
                              label="Total Currency"
                              type="number"
                              required
                            >
                              <template v-slot:label>
                                <p class="body-1">
                                  Total Currency
                                  <span class="red--text">*</span>
                                </p>
                              </template>
                            </v-text-field>
                          </v-col> -->

                          <!-- Companies -->
                          <!-- <v-col
                            v-if="$can('super_admin')"
                            class="mt-n5"
                            cols="12"
                          >
                            <v-select
                              v-model="companyName"
                              :items="companyNamesList"
                              :rules="companyNameRules"
                              attach
                              label="Companies"
                            >
                              <template v-slot:label>
                                <p class="body-1">Company</p>
                              </template>
                            </v-select>
                          </v-col> -->

                          <!-- Message -->
                          <v-col cols="12">
                            <p class="body-1">
                              For this document you will need to upload file(s)
                              under Inbox details
                            </p>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="btn btn-primary" @click="close">
                      Cancel
                    </v-btn>
                    <v-btn color="btn btn-primary" @click="save"> Save </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5"
                    >Are you sure you want to delete this
                    document?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="btn btn-primary" @click="closeDelete"
                      >Cancel</v-btn
                    >
                    <v-btn
                      color="btn btn-primary"
                      @click="deleteDocumentConfirm"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>

          <!-- created_at -->
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.created_at="{ item }">
            {{ $helpers.datetime(item.created_at) }}
          </template>

          <!-- updated_at -->
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.updated_at="{ item }">
            {{ $helpers.datetime(item.updated_at) }}
          </template>

          <!-- actions -->
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.actions="{ item }">
            <!-- <v-icon
              v-if="$can('document_update')"
              small
              class="mr-2"
              @click="editDocument(item)"
              color="primary"
            >
              mdi-pencil
            </v-icon> -->
            <v-icon
              v-if="$can('document_delete')"
              small
              @click="deleteDocument(item)"
              color="red"
            >
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:no-data>
            <v-btn color="primary"> No Data Available </v-btn>
          </template>
        </v-data-table>

        <v-row class="text-center px-4 align-center mt-4" wrap>
          <v-col class="text-truncate" cols="12" md="2">
            Total {{ totalRecords }} records
          </v-col>

          <v-col cols="12" md="6">
            <v-pagination v-model="page" :length="pageCount"> </v-pagination>
          </v-col>

          <v-col cols="6" md="3">
            <v-select
              v-model="perPageChoice"
              label="Documents per page"
              item-text="text"
              item-value="value"
              :items="perPageChoices"
              dense
              outlined
              hide-details
              return-object
            >
            </v-select>
          </v-col>
        </v-row>
      </v-card>

      <!-- Overlay -->
      <v-overlay :value="overlay" z-index="1000">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-card>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  name: "DocumentManagementDocuments",

  props: {
    redirect: {
      default: "",
      type: String,
    },
  },

  data() {
    return {
      overlay: false,

      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),

      dateMenu: false,

      dialog: false,
      dialogDelete: false,
      headers: [
        { text: "Id", align: "start", value: "id" },
        // { text: "Date", value: "date" },
        // { text: "time", value: "time" },
        // { text: "Vendor Name", value: "vendor_name" },
        // { text: "Tax", value: "tax" },
        // { text: "Total Before Tax", value: "total_before_tax" },
        // { text: "Total", value: "total" },
        // { text: "Total Currency", value: "total_currency" },
        { text: "Company Id", value: "company_id" },
        { text: "Created At", value: "created_at" },
        { text: "Updated At", value: "updated_at" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      documents: [],
      companies: [],
      companyName: "",
      companyNamesList: [],
      editedIndex: -1,
      editedDocument: {
        id: 0,
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000),
        time: "",
        // vendor_name: "",
        tax: 0,
        total_before_tax: 0,
        total: 0,
        total_currency: 0,
        company_id: 0,
        created_at: "",
        updated_at: "",
      },
      defaultDocument: {
        id: 0,
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000),
        time: "",
        // vendor_name: "",
        tax: 0,
        total_before_tax: 0,
        total: 0,
        total_currency: 0,
        company_id: 0,
        created_at: "",
        updated_at: "",
      },

      // vendor name
      vendorNameRules: [
        (v) => !!v || "Required",
        (v) =>
          /^[a-zA-Z][a-zA-Z0-9 \-']+$/.test(v) ||
          "Only english letters, apostrophes and hyphens are allowed",
        (v) => v.length >= 3 || "Can not be less than 3 characters",
        (v) => v.length <= 100 || "Can not be more than 100 characters",
      ],

      // tax
      taxRules: [
        (v) => !!v || "Required",
        (v) => /^[0-9\s]+$/.test(v) || "Tax must contain only 0 to 9",
        (v) => (v && v >= 0) || "Tax should be 0 or above",
        (v) =>
          v.length <= 255 || "Last name can not be more than 255 characters",
      ],

      // total before tax
      totalBeforeTaxRules: [
        (v) => !!v || "Required",
        (v) =>
          /^[0-9\s]+$/.test(v) || "Total Before Tax must contain only 0 to 9",
        (v) => (v && v >= 0) || "Total Before Tax should be 0 or above",
        (v) =>
          v.length <= 255 ||
          "Total Before Tax can not be more than 255 characters",
      ],

      // total
      totalRules: [
        (v) => !!v || "Required",
        (v) => /^[0-9\s]+$/.test(v) || "Total must contain only 0 to 9",
        (v) => (v && v >= 0) || "Total should be 0 or above",
        (v) => v.length <= 255 || "Total can not be more than 255 characters",
      ],

      // total currency
      totalCurrencyRules: [
        (v) => !!v || "Required",
        (v) =>
          /^[0-9\s]+$/.test(v) || "Total currency must contain only 0 to 9",
        (v) => (v && v >= 0) || "Total currency should be 0 or above",
        (v) =>
          v.length <= 255 ||
          "Total currency can not be more than 255 characters",
      ],

      // companyName
      companyNameRules: [],

      page: 1,
      documentsPerPage: 100,
      totalRecords: 0,
      pageCount: 0,
      perPageChoice: { text: "100 records/page", value: 100 },
      perPageChoices: [
        { text: "5 records/page", value: 5 },
        { text: "25 records/page", value: 25 },
        { text: "50 records/page", value: 50 },
        { text: "100 records/page", value: 100 },
        { text: "150 records/page", value: 150 },
        { text: "200 records/page", value: 200 },
      ],
    };
  },

  computed: {
    ...mapState({ auth: "auth" }),

    formTitle() {
      return this.editedIndex === -1 ? "New Document" : "Edit Document";
    },
  },

  watch: {
    // dialog
    dialog(val) {
      val || this.close();
    },

    // dialogDelete
    dialogDelete(val) {
      val || this.closeDelete();
    },

    // page
    page() {
      this.getApiPagedDocuments();
    },

    // documentsPerPage
    documentsPerPage() {
      this.getApiPagedDocuments();
    },

    // perPageChoice
    perPageChoice(val) {
      this.documentsPerPage = val.value;
      this.page = 1;
    },
  },

  created() {
    if (!this.$can("document_access")) {
      this.$router.push({ name: "DashboardInbox" });
    }

    // this.getApiDocuments();
    this.getApiPagedDocuments();

    if (this.$can("super_admin")) {
      this.getApiCompanies();
    }
  },

  methods: {
    ...mapMutations({
      resetAuthState: "auth/resetState",
    }),

    // validateForm
    validateForm() {
      this.$refs.form.validate();

      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          this.updateApiDocument();
        } else {
          this.createApiDocument();
        }
      }
    },

    // editDocument
    editDocument(document) {
      this.editedIndex = this.documents.indexOf(document);
      this.editedDocument = Object.assign({}, document);
      this.dialog = true;

      if (this.editedIndex != -1) {
        this.companyName = this.$helpers.getNameByIdFromList(
          this.companies,
          document.company_id
        );
      }

      this.$refs.form.resetValidation();
    },

    // deleteDocument
    deleteDocument(document) {
      this.editedIndex = this.documents.indexOf(document);
      this.editedDocument = Object.assign({}, document);
      this.dialogDelete = true;
    },

    // deleteDocumentConfirm
    deleteDocumentConfirm() {
      this.deleteApiDocument();
    },

    // close
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedDocument = Object.assign({}, this.defaultDocument);
        this.editedIndex = -1;
        this.companyName = "";
      });

      this.$refs.form.resetValidation();
    },

    // closeDelete
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedDocument = Object.assign({}, this.defaultDocument);
        this.editedIndex = -1;
      });
    },

    // save
    save() {
      this.validateForm();
    },

    // Get Api Documents
    async getApiDocuments() {
      this.overlay = true;

      const res = await this.$helpers.getApiData("documents");

      if (res.status == 200) {
        this.documents = res.data;
      }

      this.overlay = false;
    },

    // Get Api Paged Documents
    async getApiPagedDocuments() {
      this.overlay = true;

      const res = await this.$helpers.getApiData(
        `documents/paged?page_no=${this.page}&page_size=${this.documentsPerPage}`
      );

      if (res.status == 200) {
        this.documents = res.data.data;
        this.totalRecords = res.data.item_count;
        this.pageCount = res.data.page_count;

        this.$vuetify.goTo(0);
      }

      this.overlay = false;
    },

    // Create Api Document
    async createApiDocument() {
      this.overlay = true;

      var reqData = {
        // vendor_name: this.editedDocument.vendor_name,
        tax: parseInt(this.editedDocument.tax),
        total_before_tax: parseInt(this.editedDocument.total_before_tax),
        total: parseInt(this.editedDocument.total),
        total_currency: parseInt(this.editedDocument.total_currency),
        company_id: this.auth.user.company_id,
      };

      if (this.companyName != "") {
        var id = this.$helpers.getIdByNameFromList(
          this.companies,
          this.companyName
        );

        if (id > 0) {
          reqData.company_id = id;
        }
      }

      const res = await this.$helpers.createApiData(
        "documents",
        reqData,
        "Document Created Successfully!"
      );

      if (res.status == 200) {
        this.documents.push(res.data);

        this.close();
      }

      this.overlay = false;
    },

    // Update Api Document
    async updateApiDocument() {
      this.overlay = true;

      var reqData = {
        id: this.editedDocument.id,
        vendor_name: this.editedDocument.vendor_name,
        tax: parseInt(this.editedDocument.tax),
        total_before_tax: parseInt(this.editedDocument.total_before_tax),
        total: parseInt(this.editedDocument.total),
        total_currency: parseInt(this.editedDocument.total_currency),
        company_id: this.auth.user.company_id,
      };

      if (this.companyName != "") {
        var id = this.$helpers.getIdByNameFromList(
          this.companies,
          this.companyName
        );

        if (id > 0) {
          reqData.company_id = id;
        }
      }

      const res = await this.$helpers.updateApiData(
        `documents/${this.editedDocument.id}`,
        reqData,
        "Document Updated Successfully!"
      );

      if (res.status == 200) {
        Object.assign(this.documents[this.editedIndex], res.data);

        this.close();
      }

      this.overlay = false;
    },

    // Delete Api Document
    async deleteApiDocument() {
      this.overlay = true;

      const res = await this.$helpers.deleteApiData(
        `documents/${this.editedDocument.id}`,
        "Document Deleted Successfully!"
      );

      if (res.status == 200) {
        this.documents.splice(this.editedIndex, 1);

        this.closeDelete();
      }

      this.overlay = false;
    },

    // Get Api Companies
    async getApiCompanies() {
      this.overlay = true;

      const res = await this.$helpers.getApiData("companies");

      if (res.status == 200) {
        this.companies = res.data;

        this.companyNamesList = this.$helpers.getNamesFromList(this.companies);
      }

      this.overlay = false;
    },
  },
};
</script>

<style lang="scss" scoped>
//
</style>
